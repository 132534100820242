<template>
  <div id="customerOverviewPage">
    <!-- Page Header -->
    <el-card class="pageHeader" shadow="never">
      <div slot="header">
        <div class="headerText">Customers Overview</div>
      </div>
      <div class="sizeDetailsContainer" v-loading="sizeLoader">
        <el-row v-if="!sizeLoader">
          <el-col :span="8" class="columnItem borderRight">
            <div class="label">Registered Customers</div>
            <div class="value">
              {{ getNumberWithComma(data.registered) }}
            </div>
            <div class="percentage">
              {{ data.registeredPerc + '%' }}
            </div>
          </el-col>
          <el-col :span="8" class="columnItem borderRight">
            <div class="label">Anonymous Users</div>
            <div class="value">
              {{ getNumberWithComma(data.unknown) }}
            </div>
            <div class="percentage">
              {{ data.unknownPerc + '%' }}
            </div>
          </el-col>
          <el-col :span="8" class="columnItem">
            <div class="label">Total Customers</div>
            <div class="value">
              {{ getNumberWithComma(data.total) }}
            </div>
            <div class="percentage">&nbsp;</div>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <el-card shadow="never" class="box-card channelReachablityCard" v-if="!sizeLoader">
      <div class="cardHeader" slot="header">
        <span>Channel Reachablity</span>
      </div>

      <div class="reachabliyTableContainer">
        <el-table :data="reachablity" style="width: 100%">
          <el-table-column prop="name" label="Channel" width="280"> </el-table-column>
          <el-table-column prop="percentage" label="Reachablity %" width="280"> </el-table-column>
          <el-table-column prop="unsubscribed" label="Unsubscribed"> </el-table-column>
          <el-table-column label="Invalid Detials">
            <template slot-scope="scope">
              {{ scope.row.invalid }} &nbsp;
              <i v-if="scope.row.invalid != '--'" @click="showInvalidContactPopup(scope.row)" class="el-icon el-icon-info cursor-pointer"></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <el-dialog custom-class="invalidContactsPopup" ref="invalidContactsPopup" append-to-body title="Invalid Contacts" width="800px" :visible.sync="invalidContactPopup.visible">
      <!-- Loader -->
      <div v-if="invalidContactPopup.fetchings" v-loading="true" class="w-full border bg-white h-80 rounded overflow-hidden"></div>

      <!-- List -->
      <div v-else>
        <el-table :data="invalidContactPopup.list" style="width: 100%">
          <el-table-column prop="type" label="Failure Type" width="230"> </el-table-column>
          <el-table-column prop="reason" label="Failure Reason"> </el-table-column>
          <el-table-column prop="cnt" label="Count" width="150"> </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>
<style lang="scss" src="./customerOverview.scss"></style>

<script>
import customerOverviewComponent from './customerOverviewComponent';
export default customerOverviewComponent;
</script>
