import customerModule from '@/services/customer';

export default {
  name: 'CustomerOverview',
  components: {},
  data() {
    return {
      // Customer Size
      sizeLoader: true,
      data: null,
      reachablity: null,

      invalidContactPopup: {
        visible: false,
        channel: null,

        fetching: false,
        pageNo: 1,
        pageSize: 50,

        total: null,
        list: []
      }
    };
  },

  methods: {
    // Segment Size Fetch
    async fetchSizing() {
      try {
        this.sizeLoader = true;
        let result = await customerModule.getOverviewData(this);
        this.data = result.data;
        this.setReachablity(this.data.channels);
        this.sizeLoader = false;
      } catch (e) {
        this.errorToast('Something went wrong. Failed to fetch details.');
        this.reportError(e);
        this.sizeLoader = false;
      }
    },

    // Segment Reachablity Fetch
    async setReachablity(response) {
      try {
        // Iterate and calculate percentage reachablity
        this.reachablity = [
          { key: 'email', name: 'Email' },
          { key: 'sms', name: 'SMS' },
          { key: 'whatsApp', name: 'WhatsApp' },
          { key: 'webPush', name: 'Web Push' },
          { key: 'mobilePush', name: 'Mobile Push' },
          { key: 'inApp', name: 'Mobile In-App' }
        ];

        // Calculate percentage
        for (let i = 0; i < this.reachablity.length; i++) {
          let key = this.reachablity[i].key;
          let percentage = 0;
          if (this.data.total && this.data.total != 0) {
            percentage = (100 * response[key]) / this.data.registered;
            percentage = percentage.toFixed(2) + '%';
          }

          this.reachablity[i].percentage = percentage;
          this.reachablity[i].count = response[key];

          if (key == 'email') {
            this.reachablity[i].unsubscribed = response.emailUnsubscribed;
            this.reachablity[i].invalid = response.emailInvalid;
          } else if (key == 'sms') {
            this.reachablity[i].unsubscribed = response.smsUnsubscribed;
            this.reachablity[i].invalid = response.smsInvalid;
          } else if (key == 'whatsApp') {
            this.reachablity[i].unsubscribed = response.whatsappUnsubscribed;
            this.reachablity[i].invalid = response.whatsAppInvalid;
          } else {
            this.reachablity[i].unsubscribed = '--';
            this.reachablity[i].invalid = '--';
          }
        }
      } catch (e) {
        this.errorToast('Something went wrong.');
        this.reportError(e);
        this.reachablityLoader = false;
      }
    },

    //#region -------------- Start: Invalid Contacts Popup ----------------------------

    async showInvalidContactPopup(row) {
      this.invalidContactPopup.total = row.count;
      this.invalidContactPopup.channel = row.key;
      this.invalidContactPopup.pageNo = 1;
      this.invalidContactPopup.pageSize = 50;
      this.invalidContactPopup.fetching = true;

      this.invalidContactPopup.visible = true;

      requestAnimationFrame(() => {
        this.fetchInvalidContactDetails();
      });
    },

    async fetchInvalidContactDetails() {
      try {
        this.invalidContactPopup.fetching = true;

        let params = {
          channel: this.invalidContactPopup.channel,
          page: this.invalidContactPopup.pageNo,
          limit: this.invalidContactPopup.pageSize
        };

        let result = await customerModule.getOverviewOfInvalidContacts(params);
        console.log('Invalid contact info result', result.data);

        this.invalidContactPopup.list = result.data;
        this.invalidContactPopup.fetching = false;
      } catch (e) {
        this.errorToast('Failed to fetch invalid contact details. Please contact support.');
        this.reportError(e);
      }
    }

    //#region -------------- End: Invalid Contacts Popup ----------------------------
  },

  async mounted() {
    this.fetchSizing();
  }
};
